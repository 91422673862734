<template>
  <div class="page404">
    <a class="back-icon" href="javascript:history.back(-1)">
      <img alt="" src="../assets/images/left-arrow.png" />
    </a>
    <textarea class="key-value" id="code" cols="30" rows="1" contentEditable=true style="background:#343445;font-size:18pt;border:0px;resize:none;color:#2c3e50;">{{ $route.params.key }}</textarea>
    <div class="btn-copy flex-c favorite styled" @click="copyKeyValue">点击转跳九鼎</div>
  </div>
</template>

<script>
// @ is an alias to /src
// import { isMobile } from '../utils'
// import { get, post } from '../axios'
export default {
  name: 'pageError',
  methods: {
    copyKeyValue() {
        var i = document.getElementById("code");
        i.select();
        document.execCommand("Copy");
        this.$message.success('复制成功', 2)
        window.location.href = `${this.$route.params.url}`;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';
.styled {
    border: 0;
    line-height: 2.5;
    padding: 0 20px;
    font-size: 1rem;
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    border-radius: 10px;
    background-color: rgba(220, 0, 0, 1);
    background-image: linear-gradient(to top left,
                                      rgba(0, 0, 0, .2),
                                      rgba(0, 0, 0, .2) 30%,
                                      rgba(0, 0, 0, 0));
    box-shadow: inset 2px 2px 3px rgba(255, 255, 255, .6),
                inset -2px -2px 3px rgba(0, 0, 0, .6);
}

.styled:hover {
    background-color: rgba(255, 0, 0, 1);
}

.styled:active {
    box-shadow: inset -2px -2px 3px rgba(255, 255, 255, .6),
                inset 2px 2px 3px rgba(0, 0, 0, .6);
}

.page404 {
  background-size: 100% 100%;
  max-width: 30rem;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  position: relative;
}

.back-icon {
  img {
    width: 1.2rem;
  }

  position: absolute;
  left: 0.2rem;
  top: 0.5rem;
}

.key-value {
  position: absolute;
  width: 100%;
  padding: 0 10px;
  left: 0;
  right: 0;
  top: 20%;
  color: white;
  font-size: 0.6rem;
  word-break: break-all;
}

.btn-copy {
  width: 8rem;
  padding: 0.1rem 0;
  margin: 0 auto;
  color: white;
  font-size: 0.9rem;
  border-radius: 1rem / 1rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 25%;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

@include md {
}
</style>
